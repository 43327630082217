import React, { useState } from "react";
import { Button } from "@shadcn/button";
import {
  DialogDescription,
  DialogFooter,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
  Dialog,
} from "@shadcn/dialog";
import { usePostHog } from "posthog-js/react";
import SuggestProjectResults from "./SuggestProjectResults";

interface SuggestProjectButtonDialogProps {
  children: React.ReactNode;
  goalId?: string;
}

export function SuggestProjectButtonDialog({ children, goalId }: SuggestProjectButtonDialogProps) {
  const [showResults, setShowResults] = useState(false);
  const [open, setOpen] = useState(false);

  const effectiveGoalId = goalId;
  const posthog = usePostHog();

  const getProjects = () => {
    posthog?.capture("app_ai_executed", { command_type: "suggest_project" });
    setShowResults(true);
  };

  const resetView = () => {
    setShowResults(false);
  };

  return (
    <div className="sm:max-w-[800px] max-h-[70vh] overflow-auto">
      <Dialog
        open={open}
        onOpenChange={(state) => {
          if (!state) resetView();
          setOpen(state);
        }}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent className="sm:max-w-[800px] max-h-[90vh] overflow-auto">
          <DialogHeader>
            <DialogTitle className="text-2xl">Get AI-Powered Initiative Ideas</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <p className="text-base leading-normal p-4">
              Let our AI assistant analyze your goal and suggest relevant initiatives that can help you make meaningful progress.
            </p>
          </DialogDescription>
          <DialogFooter>
            {!showResults && (
              <Button type="submit" onClick={getProjects} className="w-full text-lg">
                Generate Ideas
              </Button>
            )}
          </DialogFooter>
          {showResults && effectiveGoalId && (
            <SuggestProjectResults goalId={effectiveGoalId} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
