import { Link } from "react-router-dom";
import { Project } from "@shared/types";
import { Checkbox } from "@shadcn/checkbox";
import { useUpdateProjectMutation } from "@/queries/projects";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { cn } from "@/web/lib/utils";

interface InitiativeListProps {
  initiatives: Project[];
}

export function InitiativeList({ initiatives }: InitiativeListProps) {
  const queryClient = useQueryClient();
  const updateProject = useUpdateProjectMutation();
  const [completingIds, setCompletingIds] = useState<Set<string>>(new Set());

  const handleToggleComplete = async (initiative: Project, e: React.MouseEvent) => {
    e.preventDefault(); // Prevent navigation
    if (initiative.completed) {
      // For uncompleting, update immediately
      await updateProject.mutateAsync({
        id: initiative.id,
        completed: false,
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["projects"] });
          queryClient.invalidateQueries({ queryKey: ["projects", initiative.id] });
          queryClient.invalidateQueries({ queryKey: ["projects", { completed: true, goalId: initiative.goalId }] });
        }
      });
    } else {
      // For completing, add animation
      setCompletingIds(prev => new Set([...prev, initiative.id]));
      
      // Delay the actual update
      setTimeout(async () => {
        await updateProject.mutateAsync({
          id: initiative.id,
          completed: true,
        }, {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["projects"] });
            queryClient.invalidateQueries({ queryKey: ["projects", initiative.id] });
            queryClient.invalidateQueries({ queryKey: ["projects", { completed: false, goalId: initiative.goalId }] });
            // Remove from completing state after another delay
            setTimeout(() => {
              setCompletingIds(prev => {
                const next = new Set(prev);
                next.delete(initiative.id);
                return next;
              });
            }, 500);
          }
        });
      }, 500);
    }
  };

  return (
    <>
      {initiatives.map((initiative) => {
        const isCompleting = completingIds.has(initiative.id);
        return (
          <Link
            key={initiative.id}
            to={`/projects/${initiative.id}`}
            className={cn(
              "block transition-all duration-500 ease-in-out",
              isCompleting && "opacity-50 translate-x-2"
            )}
          >
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={initiative.completed || isCompleting}
                  onClick={(e) => handleToggleComplete(initiative, e)}
                  className={cn(
                    "transition-all duration-300",
                    "data-[state=checked]:bg-primary",
                    isCompleting && "scale-110"
                  )}
                />
                <div className={cn(
                  "font-medium transition-all duration-300",
                  (initiative.completed || isCompleting) && "line-through text-muted-foreground"
                )}>{initiative.name}</div>
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
}