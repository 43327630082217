import { create } from 'zustand'
import { Message, CreateMessage, ChatRequestOptions } from 'ai'

type ChatType = "dashboard" | "coachsession";

interface ChatState {
  isChatVisible: boolean
  setIsChatVisible: (visible: boolean) => void
  messages: Message[]
  setMessages: (messages: Message[]) => void
  addMessage: (message: Message) => void
  append: ((message: Message | CreateMessage, chatRequestOptions?: ChatRequestOptions) => Promise<string | null | undefined>) | null
  setAppend: (append: (message: Message | CreateMessage, chatRequestOptions?: ChatRequestOptions) => Promise<string | null | undefined>) => void
  goalId: string | undefined
  setGoalId: (goalId: string | undefined) => void
  chatType: ChatType
  setChatType: (type: ChatType) => void
  reset: () => void
}

export const useChatContext = create<ChatState>((set) => ({
  isChatVisible: false,
  setIsChatVisible: (visible) => set({ isChatVisible: visible }),
  messages: [],
  setMessages: (messages) => set({ messages }),
  addMessage: (message) => set((state) => ({ messages: [...state.messages, message] })),
  append: null,
  setAppend: (append) => set({ append }),
  goalId: undefined,
  setGoalId: (goalId) => set({ goalId }),
  chatType: "dashboard",
  setChatType: (type) => set({ chatType: type }),
  reset: () => set({ messages: [], goalId: undefined, chatType: "dashboard" }),
}))
