import { useAuthContext } from "@/providers/AuthProvider";
import "@/styles/md-container.css";
import { Button } from "@shadcn/button";
import { Textarea } from "@shadcn/textarea";
import { useChat } from "ai/react";
import { ChevronsRight, SendHorizonal, TargetIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState, KeyboardEvent, useMemo } from "react";
import { useChatContext } from "@/web/hooks/useChatContext";
import { Message, ExtendedMessage } from "./Message";
import { EmptyState } from "./EmptyState";
import { useScrollToBottom } from "./useScrollToBottom";
import { useFetchGoalByGoalId } from "@/queries/goals";
import { v4 as uuidv4 } from 'uuid';
import { coaches } from "./coaches";
import { useFetchUserById } from "@/queries/user";
import { useFeatureFlagEnabled } from "posthog-js/react";

function LoadingIndicator() {
  return (
    <div className="flex items-center space-x-1 animate-pulse p-4">
      <div className="w-1 h-1 bg-gray-500 rounded-full"></div>
      <div className="w-1 h-1 bg-gray-500 rounded-full animation-delay-150"></div>
      <div className="w-1 h-1 bg-gray-500 rounded-full animation-delay-300"></div>
    </div>
  );
}

function ChatInput({
  input,
  isLoading,
  onInputChange,
  onSubmit,
  textareaRef,
}: {
  input: string;
  isLoading: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}) {
  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "40px";
      const scrollHeight = textarea.scrollHeight;
      textarea.style.height = input ? `${Math.min(scrollHeight, 200)}px` : "40px";
    }
  }, [input]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [input, adjustTextareaHeight]);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSubmit(e as any);
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-end gap-2">
        <Textarea
          ref={textareaRef}
          value={input}
          onChange={onInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Message Lifehack AI Coach"
          className="flex-grow h-10 min-h-[40px] max-h-[200px] resize-none bg-transparent border-none focus-visible:ring-0 focus-visible:ring-offset-0 focus:outline-none overflow-y-auto"
        />
        <div className="flex items-center gap-2">
          <Button
            onClick={(e) => onSubmit(e as any)}
            className="rounded-full flex-shrink-0"
            disabled={isLoading}
          >
            <SendHorizonal size={16} />
          </Button>
        </div>
      </div>
    </div>
  );
}

function ChatHeader({ onClose }: { onClose: () => void }) {
  const { goalId } = useChatContext();
  const { data: goal } = useFetchGoalByGoalId(goalId);
  return (
    <div className="flex items-center justify-between max-w-full">
      <div
        onClick={onClose}
        className="cursor-pointer w-10 h-10 flex items-center justify-center flex-shrink-0"
      >
        <ChevronsRight size={16} />
      </div>
      <span className="font-semibold whitespace-nowrap px-2 flex-shrink-0">Ask AI Coach</span>
      <span className="px-3 py-1.5 bg-brand-secondary/30 rounded-full flex items-center min-w-0 flex-shrink">
        <TargetIcon size={12} className="mr-1 flex-shrink-0" />
        <span className="text-xs text-gray-700 truncate overflow-hidden leading-none pb-[1px]">{goal?.name}</span>
      </span>
    </div>
  );
}

function ChatWindow({
  onClose,
}: {
  onClose: () => void;
}) {
  const { token, user } = useAuthContext();
  const { setIsChatVisible, setAppend, chatType, goalId } = useChatContext();
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { data: userData } = useFetchUserById(user?.uid || "");
  const defaultCoach = useMemo(() => coaches[0].id, []);
  const isCoachPersonalityEnabled = useFeatureFlagEnabled('coach-personality');
  const [selectedCoach, setSelectedCoach] = useState(defaultCoach);
  const [messagesContainerRef, messagesEndRef] = useScrollToBottom<HTMLDivElement>();

  useEffect(() => {
    if (isCoachPersonalityEnabled && userData?.preferredCoachId && coaches.some(coach => coach.id === userData.preferredCoachId)) {
      setSelectedCoach(userData.preferredCoachId);
    } else {
      setSelectedCoach(defaultCoach);
    }
  }, [userData?.preferredCoachId, defaultCoach, isCoachPersonalityEnabled]);

  const chatId = useMemo(() => `${chatType}-${goalId}-${uuidv4()}`, [chatType, goalId]);

  const getCoachName = (coachId: string) => coaches.find(coach => coach.id === coachId)?.name || 'Unknown';

  const {
    input,
    handleInputChange,
    handleSubmit,
    isLoading,
    append,
    messages: chatMessages,
  } = useChat({
    api: `${process.env.REACT_APP_AI_API_URL}/v1/chats/dashboard`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      chatType: chatType,
      coachPersonality: selectedCoach,
    },
    keepLastMessageOnError: true,
    onResponse: (response) => {
      console.log('onResponse called', response);
      setIsWaitingForResponse(false);
    },
    onFinish: (message) => {
      console.log('onFinish called', message);
    },
    id: chatId,
  });

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsWaitingForResponse(true);
    handleSubmit(e);
  };

  const handleClose = () => {
    setIsChatVisible(false);
    onClose();
  };

  useEffect(() => {
    if (append) {
      console.log("setting appending function");
      setAppend((message, options) => {
        setIsWaitingForResponse(true);
        return append(message, options);
      });
    }
  }, []);

  return (
    <div className="w-full flex flex-col h-[100dvh] md:h-full">
      <div className="flex-shrink-0 px-4 py-3 border-b">
        <ChatHeader onClose={handleClose} />
      </div>
      <div 
        className="flex-1 overflow-y-auto overflow-x-hidden px-4"
        ref={messagesContainerRef}
      >
        {chatMessages.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            {chatMessages.map((message) => (
              <div key={message.id}>
                <Message 
                  message={message as ExtendedMessage} 
                  coachName={getCoachName(selectedCoach)}
                />
              </div>
            ))}
            {isWaitingForResponse && <LoadingIndicator />}
          </>
        )}
        <div
          ref={messagesEndRef}
          className="flex-shrink-0 min-w-[24px] min-h-[24px]"
        />
      </div>
      <div className="flex-shrink-0 p-4">
        <div className="relative">
          <div className="w-full p-4 bg-slate-100 rounded-md">
            <ChatInput
              input={input}
              isLoading={isLoading}
              onInputChange={handleInputChange}
              onSubmit={handleFormSubmit}
              textareaRef={textareaRef}
            />
          </div>
        </div>
        <p className="text-xs text-gray-500 mt-2 text-center">
          AI can make mistakes and should be only used as a reference.
        </p>
      </div>
    </div>
  );
}

export default ChatWindow;
