import { ReactNode, useEffect, useState, useCallback } from 'react';
import useSettingsStore from '@/store/settings';
import { FeatureBubbleContext, positionClasses, arrowPositionClasses } from './FeatureBubbleContext';

interface FeatureBubbleProps {
  children: ReactNode;
  featureId: string;
  title: string;
  message: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

export default function FeatureBubble({ children, featureId, title, message, position = 'top' }: FeatureBubbleProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { dismissedFeatures, dismissFeature } = useSettingsStore();

  useEffect(() => {
    // Show bubble after a short delay if not dismissed
    if (!dismissedFeatures.includes(featureId)) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [featureId, dismissedFeatures]);

  const handleDismiss = useCallback(() => {
    setIsVisible(false);
    dismissFeature(featureId);
  }, [featureId, dismissFeature]);

  return (
    <FeatureBubbleContext.Provider value={{ dismissBubble: handleDismiss }}>
      <div className="relative inline-block">
        {children}
        {isVisible && !dismissedFeatures.includes(featureId) && (
          <div 
            className={`absolute z-50 bg-gray-100 text-gray-900 rounded-lg p-3 shadow-lg whitespace-normal w-[280px] ${positionClasses[position]}`}
          >
            <div className="font-semibold mb-1">{title}</div>
            <div className="text-sm text-gray-600 mb-3">{message}</div>
            <button
              onClick={handleDismiss}
              className="text-xs bg-gray-200 hover:bg-gray-300 text-gray-700 px-3 py-1.5 rounded-md transition-colors w-full text-center font-medium"
            >
              Got it
            </button>
            <div 
              className={`absolute w-2 h-2 bg-gray-100 transform rotate-45 ${arrowPositionClasses[position]}`}
            />
          </div>
        )}
      </div>
    </FeatureBubbleContext.Provider>
  );
} 