import { createContext, useContext } from 'react';

export interface FeatureBubbleContextType {
  dismissBubble: () => void;
}

export const FeatureBubbleContext = createContext<FeatureBubbleContextType | null>(null);

export function useFeatureBubble() {
  const context = useContext(FeatureBubbleContext);
  if (!context) {
    throw new Error('useFeatureBubble must be used within a FeatureBubble component');
  }
  return context;
}

export const positionClasses = {
  top: 'bottom-full left-1/2 -translate-x-1/2 mb-2',
  bottom: 'top-full right-0 sm:right-auto sm:left-1/2 sm:-translate-x-[40%] mt-2',
  left: 'right-full top-1/2 -translate-y-1/2 mr-2',
  right: 'left-full top-1/2 -translate-y-1/2 ml-2',
};

export const arrowPositionClasses = {
  top: 'bottom-[-4px] left-1/2 -translate-x-1/2',
  bottom: 'top-[-4px] right-6 sm:right-auto sm:left-[40%]',
  left: 'right-[-4px] top-1/2 -translate-y-1/2',
  right: 'left-[-4px] top-1/2 -translate-y-1/2',
}; 