import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { setupConfig } from "./apiUtils";
import { captureException } from "../utils/logging";

/**
 * Hook for sending messages to Slack
 */
export const useSendSlackMessage = () => 
  useMutation({
    mutationFn: async (message: string): Promise<void> => {
      try {
        const config = await setupConfig();
        await axios.post(
          `${process.env.REACT_APP_APP_API}/v1/tr`, 
          { message }, 
          config
        );
      } catch (error) {
        captureException("Error sending Slack message", error as Error);
        throw error;
      }
    }
  }); 