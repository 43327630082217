import { useNavigate } from "react-router-dom";
import ProcessingScreen from "./ProcessingScreen";
import LoadingIndicator from "@components/loading-indicator/LoadingIndicator";
import DashboardReport from "./DashboardReport";
import { PushPermission } from "./PushPermission";
import { Initiatives } from "./BigNorthstar/Initiatives";
import AskCoach from "./AskCoach";
import { useEffect, useState } from "react";
import { usePaywallStore } from "@/web/pages/Paywall/paywallStore";
import { BigNorthStarTitle } from "./BigNorthstar/BigNorthStarTitle";
import { useAuthContext } from "@/providers/AuthProvider";
import { useFetchUserById } from "@/queries/user";
import { useFetchPromptAnswerByGoalId } from "@/queries/promptAnswers";
import { useSendSlackMessage } from "@/queries/slack";
import log from "@/utils/logtail"

// Component to show when user needs to be onboarded
function OnboardingPrompt() {
  const navigate = useNavigate();
  
  return (
    <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-[9999] p-4 overflow-hidden">
      <div className="bg-white rounded-xl shadow-xl p-6 max-w-md w-full">
        <h2 className="text-2xl font-semibold text-primary mb-3">
          Set Your Big Northstar Goal
        </h2>
        <p className="text-gray-600 mb-6">
          It looks like you haven&apos;t set up your Big Northstar goal yet. This is an important step to get personalized coaching and track your progress.
        </p>
        <div className="flex flex-col items-center gap-3">
          <button
            onClick={() => navigate("/onboarding")}
            className="w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-primary/80 transition-colors font-medium"
          >
            Start Onboarding
          </button>
        </div>
      </div>
    </div>
  );
}

// Component to show when user needs to complete 360 report generation
function CompleteReportPrompt() {
  const navigate = useNavigate();
  
  return (
    <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-[9999] p-4 overflow-hidden">
      <div className="bg-white rounded-xl shadow-xl p-6 max-w-md w-full">
        <h2 className="text-2xl font-semibold text-primary mb-3">
          Complete Your 360 Report
        </h2>
        <p className="text-gray-600 mb-6">
          You&apos;re almost there! You need to complete the generation of your 360 Report to unlock your personalized dashboard and coaching.
        </p>
        <div className="flex flex-col items-center gap-3">
          <button
            onClick={() => navigate("/start/step5")}
            className="w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-primary/80 transition-colors font-medium"
          >
            Complete 360 Report
          </button>
        </div>
      </div>
    </div>
  );
}

export function Dashboard() {
  const navigate = useNavigate();
  const { user: currentUser } = useAuthContext();
  const [needsOnboarding, setNeedsOnboarding] = useState(false);
  const [needsReportCompletion, setNeedsReportCompletion] = useState(false);
  const { 
    data: user, 
    isLoading: isUserLoading 
  } = useFetchUserById(currentUser?.uid || "");
  const {
    data: promptAnswer,
    isLoading: isPromptLoading,
    error: promptAnswerError,
  } = useFetchPromptAnswerByGoalId(user?.bigGoalId);
  const { assessmentData, report } = usePaywallStore();
  const { mutate: sendSlackMessage } = useSendSlackMessage();

  // Combined loading state - we're loading if either:
  // 1. User data is still loading, or
  // 2. User exists with a bigGoalId and prompt answer is still loading
  const isLoading = isUserLoading || (user && user.bigGoalId && isPromptLoading);

  // If user has no big goal, meaning they are not onboarded, show appropriate prompt
  useEffect(() => {
    // Only run navigation logic if user data has finished loading
    if (isUserLoading) return;
    
    // Now we know user data has loaded, check if they need onboarding
    if (user && !user.bigGoalId) {
      // If there's assessment data in paywall store but no report, show report completion prompt
      if (assessmentData && !report) {
        log.info("User needs to complete 360 report generation");
        sendSlackMessage(`User ${currentUser?.uid} shown 360 report completion prompt on dashboard`);
        setNeedsReportCompletion(true);
        setNeedsOnboarding(false);
        return;
      }

      // Otherwise show onboarding prompt
      log.info("User without bigGoalId detected, showing onboarding prompt");
      sendSlackMessage(`User ${currentUser?.uid} shown onboarding prompt on dashboard`);
      setNeedsOnboarding(true);
      setNeedsReportCompletion(false);
    } else {
      // Reset the states if user has a bigGoalId
      setNeedsOnboarding(false);
      setNeedsReportCompletion(false);
    }
  }, [user, isUserLoading, navigate, assessmentData, report, currentUser, sendSlackMessage]);

  // Show loading indicator while data is being fetched
  if (isLoading) {
    return <LoadingIndicator />;
  }

  // Render the dashboard content
  const renderDashboardContent = () => {
    if (promptAnswerError) {
      return (
        <div className="flex flex-col items-center justify-center min-h-[50vh] p-4 max-w-xl mx-auto">
          <h2 className="text-xl font-semibold text-primary mb-2">
            Oops! Something is outdated.
          </h2>
          <p className="text-gray-600 text-center mb-4">
            It seems you have an older version of your 360 Report that needs to be
            updated. Please contact our support team and we&apos;ll help fix this
            right away.
          </p>
          <div className="flex flex-col items-center gap-3">
            <button
              onClick={() =>
                (window.location.href =
                  "mailto:support@lifehack.org?subject=Dashboard%20Error%20-%20Old%20360%20Report")
              }
              className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/80 transition-colors"
            >
              Contact Support
            </button>
          </div>
        </div>
      );
    }

    // If user exists but no prompt answer after loading is complete
    if (user && user.bigGoalId && !promptAnswer) {
      return null;
    }

    if (
      promptAnswer?.isWaitingHumanCoach === true
    ) {
      return <ProcessingScreen />;
    }

    return (
      <div className="p-2 md:p-6">
        <PushPermission />
        <BigNorthStarTitle />
        {promptAnswer && (
          <>
            <AskCoach goalId={promptAnswer.goalId} />
            <Initiatives />
            <DashboardReport prompt={promptAnswer} />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="relative min-h-screen">
      {renderDashboardContent()}
      {needsReportCompletion && <CompleteReportPrompt />}
      {needsOnboarding && <OnboardingPrompt />}
    </div>
  );
}
