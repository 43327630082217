import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

type Theme = "dark" | "light";

interface SettingsState {
  activeTab: string;
  activeGoal: string;
  activeProject: string;
  theme: Theme;
  language: string;
  sidebarHidden: boolean;
  dismissedFeatures: string[];
  updateSettings: (settings: Partial<SettingsState>) => void;
  toggleSidebarHidden: () => void;
  dismissFeature: (featureId: string) => void;
  resetDismissedFeatures: () => void;
}

const useSettingsStore = create<SettingsState>()(
  devtools(
    persist(
      (set) => ({
        activeTab: "goals",
        activeGoal: "",
        activeProject: "",
        theme: "light",
        language: "en",
        sidebarHidden: false,
        dismissedFeatures: [],
        updateSettings: (settings) => set((state) => ({ ...state, ...settings })),
        toggleSidebarHidden: () => set((state) => ({ sidebarHidden: !state.sidebarHidden })),
        dismissFeature: (featureId) => set((state) => ({
          dismissedFeatures: [...state.dismissedFeatures, featureId]
        })),
        resetDismissedFeatures: () => {
          if (process.env.NODE_ENV === 'development') {
            set({ dismissedFeatures: [] });
          }
        },
      }),
      {
        name: "settings-storage",
      }
    )
  )
);

// Dev only - attach to window for easy reset
if (process.env.NODE_ENV === 'development') {
  (window as any).resetFeatures = () => useSettingsStore.getState().resetDismissedFeatures();
}

export default useSettingsStore;
