import { UserCircle2 } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger } from "@shadcn/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@shadcn/alert-dialog";
import { coaches } from "../Chat/coaches";
import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "@/providers/AuthProvider";
import { useUpdateUserMutation } from "@/queries/user";
import { useFeatureBubble } from "@/web/components/FeatureBubbleContext";

interface ChooseCoachProps {
  selectedCoach: string;
  setSelectedCoach: (coachId: string) => void;
  onReset?: () => void;
}

function ChooseCoach({ selectedCoach, setSelectedCoach, onReset }: ChooseCoachProps) {
  const { user } = useAuthContext();
  const updateUser = useUpdateUserMutation();
  const [showTooltip, setShowTooltip] = useState(false);
  const [pendingCoachId, setPendingCoachId] = useState<string | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const tooltipTimeoutRef = useRef<NodeJS.Timeout>();
  const { dismissBubble } = useFeatureBubble();

  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, []);

  const handleCoachChange = (coachId: string) => {
    setPendingCoachId(coachId);
    setShowConfirmDialog(true);
  };

  const confirmCoachChange = () => {
    if (pendingCoachId && user?.uid) {
      setSelectedCoach(pendingCoachId);
      updateUser.mutate({ preferredCoachId: pendingCoachId });
      setShowTooltip(true);
      
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
      
      tooltipTimeoutRef.current = setTimeout(() => {
        setShowTooltip(false);
      }, 2000);

      // Reset the chat
      onReset?.();
    }
    setShowConfirmDialog(false);
    setPendingCoachId(null);
  };

  const cancelCoachChange = () => {
    setShowConfirmDialog(false);
    setPendingCoachId(null);
  };

  return (
    <>
      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Change Coach</AlertDialogTitle>
            <AlertDialogDescription>
              Changing coaches will reset your current chat. Are you sure you want to continue?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={cancelCoachChange}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmCoachChange}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <div className="relative">
        {showTooltip && (
          <div className="absolute -top-2 right-0 transform -translate-y-full text-xs text-gray-600 bg-gray-100 px-2 sm:px-3 py-1.5 rounded-md animate-in fade-in slide-in-from-bottom-2 shadow-sm z-10 min-w-[160px] text-center whitespace-nowrap">
            Coach changed to {coaches.find(c => c.id === selectedCoach)?.name}
          </div>
        )}
        <Select 
          value={selectedCoach} 
          onValueChange={handleCoachChange}
          onOpenChange={(open) => {
            if (open) {
              dismissBubble();
            }
          }}
        >
          <SelectTrigger className="w-8 h-8 p-0 border-none bg-transparent text-gray-500 hover:text-gray-900 focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0">
            <UserCircle2 className="w-5 h-5 text-gray-500 hover:text-gray-900" />
          </SelectTrigger>
          <SelectContent 
            className="z-50 max-h-[80vh] w-[280px] overflow-y-auto" 
            sideOffset={8} 
            align="end"
            position="popper"
          >
            {coaches.map((coach) => (
              <SelectItem key={coach.id} value={coach.id} className="py-3">
                <div className="space-y-1">
                  <div className="font-medium text-sm">{coach.name}</div>
                  <div className="text-xs break-words leading-relaxed text-muted-foreground">{coach.description}</div>
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );
}

export default ChooseCoach; 