'use client'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Introduction from './introduction'
import AssessmentForm from './assessment-form'
import NorthstarSuggestion from './northstar-suggestion'
import ReportGeneration from './report-generation'
import { Stepper } from './stepper'
import { X } from 'lucide-react'
import { Button } from '@shadcn/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@shadcn/dialog'

interface OnboardingProps {
  mode?: 'onboarding' | 'new-northstar'
}

export function Onboarding({ mode = 'onboarding' }: OnboardingProps) {
  const [step, setStep] = useState(1)
  const [showExitDialog, setShowExitDialog] = useState(false)
  const navigate = useNavigate()

  const steps = ["Introduction", "Fill-in Assessment", "Confirm Northstar", "Generate Report"]

  const handleAssessmentSubmit = () => {
    setStep(3)
  }

  const handleNorthstarAcceptance = () => {
    setStep(4)
  }

  const handleNorthstarRejection = () => {
    setStep(2) // Go back to assessment if not accepted
  }

  const handleExit = () => {
    setShowExitDialog(true)
  }

  const confirmExit = () => {
    navigate('/')
  }

  const copywriting = {
    onboarding: {
      title: "Welcome to Lifehack App",
      subtitle: "Your journey to achieving your Northstar starts here"
    },
    'new-northstar': {
      title: "Create a New Big Northstar",
      subtitle: "Let's set a new ambitious goal for your journey"
    }
  }

  const { title, subtitle } = copywriting[mode]

  return (
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-4xl mx-auto">
        <div className="p-6 relative">
          {mode === 'new-northstar' && (
            <button 
              onClick={handleExit}
              className="absolute top-6 right-6 text-gray-500 hover:text-gray-700 flex items-center gap-1"
            >
              <X size={16} />
              <span>Exit</span>
            </button>
          )}
          <h2 className="text-2xl font-bold">{title}</h2>
          <p className="text-gray-500">{subtitle}</p>
        </div>
        <div className="bg-white p-6 rounded-b-lg">
          <Stepper 
            currentStep={step} 
            steps={steps} 
            onStepClick={(stepNumber) => {
              if (stepNumber < step) {
                setStep(stepNumber)
              }
            }} 
          />
          {step === 1 && <Introduction onContinue={() => setStep(2)} />}
          {step === 2 && <AssessmentForm onSubmit={handleAssessmentSubmit} />}
          {step === 3 && (
            <NorthstarSuggestion
              onAccept={handleNorthstarAcceptance}
              onReject={handleNorthstarRejection}
            />
          )}
          {step === 4 && <ReportGeneration />}
        </div>
      </div>

      <Dialog open={showExitDialog} onOpenChange={setShowExitDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Exit Northstar Creation?</DialogTitle>
            <DialogDescription>
              Are you sure you want to exit? Your progress will not be saved.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex gap-2 sm:justify-end">
            <Button variant="outline" onClick={() => setShowExitDialog(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={confirmExit}>
              Exit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}