import { FolderOpenIcon, Sparkles } from "lucide-react";
import { Button } from "@shadcn/button";
import { SuggestProjectButtonDialog } from "../../../pages/AddDialogs/SuggestProjectDialog";

interface NoProjectProps {
  goalId?: string;
}

export function NoProject({ goalId }: NoProjectProps) {
  return (
    <div className="grid gap-4">
      <div className="flex flex-col items-center justify-center py-8">
        <FolderOpenIcon className="w-12 h-12 text-muted-foreground" />
        <h3 className="text-lg font-semibold">No initiatives yet</h3>
        
        {goalId && (
          <div className="mt-6">
            <SuggestProjectButtonDialog goalId={goalId}>
              <Button variant="secondary" className="flex items-center gap-2">
                <Sparkles className="w-4 h-4" />
                Get Suggestions from AI
              </Button>
            </SuggestProjectButtonDialog>
          </div>
        )}
      </div>
    </div>
  );
}