import { useFetchActiveProjectsByGoalId, useFetchCompletedProjectsByGoalId } from "@/queries/projects";
import { CardContent } from "@shadcn/card";
import { InitiativeList } from "./InitiativeList";
import { NoProject } from "./NoProject";
import { Project } from "@shared/types";
import { Skeleton } from "@shadcn/skeleton";

interface InitiativesContentProps {
  goalId: string;
  showCompleted: boolean;
}

// Skeleton loader component for initiatives
function InitiativesSkeleton() {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <div key={i} className="flex items-center gap-2 py-1.5">
          <Skeleton className="h-4 w-4 rounded" />
          <Skeleton className="h-5 w-full max-w-[250px]" />
        </div>
      ))}
    </>
  );
}

export function InitiativesContent({ goalId, showCompleted }: InitiativesContentProps) {
  const { 
    data: activeInitiatives, 
    isLoading: isLoadingActive, 
    error: errorActive 
  } = useFetchActiveProjectsByGoalId(goalId);
  
  const { 
    data: completedInitiatives, 
    isLoading: isLoadingCompleted, 
    error: errorCompleted 
  } = useFetchCompletedProjectsByGoalId(goalId);

  const isLoading = isLoadingActive || (showCompleted && isLoadingCompleted);
  const error = errorActive || (showCompleted && errorCompleted);
  
  // Determine which initiatives to display based on the showCompleted toggle
  let displayedInitiatives: Project[] = [];
  
  if (activeInitiatives) {
    displayedInitiatives = [...activeInitiatives];
  }
  
  if (showCompleted && completedInitiatives) {
    displayedInitiatives = [...displayedInitiatives, ...completedInitiatives];
  }

  // Sort initiatives to show active ones first, then completed ones
  displayedInitiatives.sort((a, b) => {
    if (a.completed === b.completed) return 0;
    return a.completed ? 1 : -1;
  });

  return (
    <CardContent className="grid gap-6">
      <div className="grid gap-2">
        {isLoading ? (
          <InitiativesSkeleton />
        ) : error ? (
          <div>Error loading initiatives.</div>
        ) : displayedInitiatives.length > 0 ? (
          <>
            <InitiativeList initiatives={displayedInitiatives} />
          </>
        ) : (
          <NoProject goalId={goalId} />
        )}
      </div>
    </CardContent>
  );
}