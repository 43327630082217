import { Sparkles, SendHorizontal, MessageCircleQuestion } from "lucide-react";
import { useChatContext } from "@/web/hooks/useChatContext";
import { useCallback, useState, useEffect } from "react";
import { posthog } from 'posthog-js';
import { useFeatureFlagEnabled } from "posthog-js/react";
import { coaches } from "../Chat/coaches";
import { useAuthContext } from "@/providers/AuthProvider";
import { useFetchUserById } from "@/queries/user";
import ChooseCoach from "./ChooseCoach";
import FeatureBubble from "@/web/components/FeatureBubble";

function AskCoach({ goalId }: { goalId: string | undefined }) {
  const { isChatVisible, setIsChatVisible, append, setGoalId, setChatType, reset } = useChatContext();
  const isCoachPersonalityEnabled = useFeatureFlagEnabled('coach-personality');
  const { user } = useAuthContext();
  const { data: userData } = useFetchUserById(user?.uid || "");
  const defaultCoach = coaches[0].id;
  const [selectedCoach, setSelectedCoach] = useState(defaultCoach);

  useEffect(() => {
    if (isCoachPersonalityEnabled && userData?.preferredCoachId && coaches.some(coach => coach.id === userData.preferredCoachId)) {
      setSelectedCoach(userData.preferredCoachId);
    } else {
      setSelectedCoach(defaultCoach);
    }
  }, [userData?.preferredCoachId, defaultCoach, isCoachPersonalityEnabled]);

  const handleSelectCard = useCallback(
    async (goal: string) => {
      // Set chat type based on the message
      if (goal === "Let's start a Coaching Session") {
        setChatType("coachsession");
      } else {
        setChatType("dashboard");
      }

      if (goalId) {
        setGoalId(goalId);
      }
      if (!isChatVisible) {
        setIsChatVisible(true);
      }
      if (append) {
        await append(
          { role: 'user', content: goal },
          { data: { coachPersonality: selectedCoach } }
        );
      }
    },
    [isChatVisible, setIsChatVisible, append, setGoalId, goalId, setChatType, selectedCoach]
  );

  const cards = [
    "Let's start a Coaching Session",
    "What's the most impactful thing I can do today for my Big Northstar?",
    "Can you remind me why I chose this Big Northstar in the first place?",
    "I'm feeling stuck. How can I break down my Big Northstar into smaller steps?",
    "What's one habit I could start that would significantly impact my Big Northstar?",
    "I'm feeling overwhelmed. What's the one thing I should focus on right now to move closer to my Big Northstar?",
  ];

  return (
    <>
      <div className="relative mt-16">
        <input
          type="text" 
          placeholder="Ask me anything about your Big Northstar..."
          className="w-full p-4 pr-16 sm:pr-24 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary/20 h-14 text-sm sm:text-base"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.currentTarget.value) {
              handleSelectCard(e.currentTarget.value);
              e.currentTarget.value = '';
            }
          }}
        />
        <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-1 sm:gap-2">
          {isCoachPersonalityEnabled && (
            <FeatureBubble 
              featureId="choose-coach-feature" 
              title="New Feature: Coaches"
              message="Choose your preferred coach! Each coach has their own unique style."
              position="bottom"
            >
              <ChooseCoach
                selectedCoach={selectedCoach}
                setSelectedCoach={setSelectedCoach}
                onReset={reset}
              />
            </FeatureBubble>
          )}
          <button 
            className="p-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
            onClick={() => {
              const input = document.querySelector('input') as HTMLInputElement;
              if (input.value) {
                handleSelectCard(input.value);
                input.value = '';
              }
            }}
          >
            <SendHorizontal className="w-5 h-5" />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4">
        {cards.map((goal, index) => {
          if (goal === "Let's start a Coaching Session" && !posthog.isFeatureEnabled('coaching-session')) {
            return null;
          }
          return (
            <div
              key={index}
              className={`p-4 border rounded-lg shadow-sm hover:shadow-md flex items-center cursor-pointer ${
                goal === "Let's start a Coaching Session" ? "bg-brand-accent-purple text-white" : ""
              }`}
              onClick={() => handleSelectCard(goal)}
            >
              {goal === "Let's start a Coaching Session" ? (
                <MessageCircleQuestion className={`${goal === "Let's start a Coaching Session" ? "text-white" : "text-slate-400"} w-5 h-5 shrink-0 mr-3`} />
              ) : (
                <Sparkles className="text-slate-400 w-5 h-5 shrink-0 mr-3" />
              )}
              <span className="flex-1">{goal}</span>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default AskCoach;
