import { Card, CardHeader, CardTitle, CardFooter } from "@shadcn/card";
import { useFetchGoals } from "@/queries/goals";
import { useAuthContext } from "@/providers/AuthProvider";
import { useFetchUserById } from "@/queries/user";
import { InitiativesContent } from "./InitiativesContent";
import { motion, AnimatePresence } from "framer-motion";
import { Plus, Sparkles } from "lucide-react";
import { Button } from "@shadcn/button";
import { AddProjectButtonDialog } from "../../../pages/AddDialogs/AddProjectButtonDialog";
import { SuggestProjectButtonDialog } from "../../../pages/AddDialogs/SuggestProjectDialog";
import { useState } from "react";

export function Initiatives() {
  const { user: currentUser } = useAuthContext();
  const { data: user } = useFetchUserById(currentUser?.uid || "");
  const { data: goals } = useFetchGoals();
  const [showCompleted, setShowCompleted] = useState(false);

  if (goals?.length === 0) return null;

  return (
    <Card className="w-full relative group mt-16 mb-8">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle className="text-3xl text-primary">Initiatives</CardTitle>
          {user?.bigGoalId && (
            <div className="flex gap-2">
              <AddProjectButtonDialog goalId={user.bigGoalId}>
                <Button variant="outline" size="sm">
                  <Plus className="w-4 h-4 mr-1" />Add
                </Button>
              </AddProjectButtonDialog>
              
              <SuggestProjectButtonDialog goalId={user.bigGoalId}>
                <Button variant="outline" size="sm">
                  <Sparkles className="w-4 h-4 mr-1" />AI
                </Button>
              </SuggestProjectButtonDialog>
            </div>
          )}
        </div>
      </CardHeader>
      <AnimatePresence mode="wait">
        <motion.div
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <InitiativesContent goalId={user?.bigGoalId || ""} showCompleted={showCompleted} />
        </motion.div>
      </AnimatePresence>
      
      {user?.bigGoalId && (
        <CardFooter className="pt-0 flex justify-end">
          <button 
            onClick={() => setShowCompleted(!showCompleted)}
            className="text-sm text-muted-foreground hover:text-primary flex items-center transition-colors"
          >
            {showCompleted ? "Hide completed" : "Show completed"}
          </button>
        </CardFooter>
      )}
    </Card>
  );
}
